<div class="header create">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h3>Nuovo utente backoffice</h3>
</div>
<mat-card>
  <form [formGroup]="creationForm" (ngSubmit)="submitForm()">
    <mat-form-field>
      <mat-label>email</mat-label>
      <input matInput formControlName="email" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Ruolo</mat-label>
      <mat-select formControlName="role" required>
        @for (item of ROLES; track $index) {
          <mat-option [value]="item.value">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
      @if (creationForm.get('role')?.errors) {
        <mat-error>Seleziona un ruolo</mat-error>
      }
    </mat-form-field>

    <br />

    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="firstName" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="lastName" required />
    </mat-form-field>

    <br />

    <div formArrayName="legalEntityCodes">
      <div *ngFor="let legalEntity of legalEntityCodes.controls; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label>Legal entity</mat-label>
          <mat-select formControlName="legalEntityCode" required>
            <mat-option *ngFor="let entity of legalEntities" [value]="entity.code">
              {{ entity.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button color="warn" (click)="removeLegalEntityCode(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button mat-raised-button color="primary" type="button" (click)="addLegalEntityCode()">Aggiungi legal entity</button>
    </div>

    <br />

    <button mat-raised-button color="primary" type="submit" [disabled]="creationForm.invalid || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>
  </form>
</mat-card>

// todo: mettere lista completa dei tipi permessi
export const CONTACT_TYPES = [
  { label: 'CELLULARE', value: 'mobilePhone' },
  { label: 'TELEFONO FISSO', value: 'phone' },
  { label: 'FAX', value: 'fax' },
  { label: 'EMAIL', value: 'email' },
];
export function getContactTypesLabel(value: string): string {
  const item = CONTACT_TYPES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { MatCard } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgForOf, NgIf } from '@angular/common';
import { PrivacyEventsRequest } from '@packages/api-cmd-type-definitions/lib/privacy/events.dto';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { ACTION_LANGUAGES, EVENT_TYPES, getActionLanguageLabel, PROOFS, SOURCES } from '../../../constants/privacy.constants';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-create-person',
  standalone: true,
  imports: [
    MatCard,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatButton,
    MatLabel,
    MatIcon,
    MatIconButton,
    NgForOf,
    MatInputModule,
    HasRoleModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    NgIf,
  ],
  templateUrl: './create-person.component.html',
  styleUrl: './create-person.component.scss',
})
export class CreatePersonComponent {
  creationForm: FormGroup;
  privacyEventTypes = EVENT_TYPES;
  privacyProofs = PROOFS;
  privacySources = SOURCES;
  public isSubmitting = false;
  actionLanguages = ACTION_LANGUAGES;
  getActionLanguage = getActionLanguageLabel;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.creationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      fieraId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      fiscalCode: [''],
      personPrivacy: this.fb.group({
        action: this.fb.group({
          language: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
          date: ['', Validators.required],
          proof: this.fb.array([
            this.fb.group({
              code: ['', Validators.required],
              value: ['', Validators.required],
            }),
          ]),
          source: this.fb.array([
            this.fb.group({
              code: ['', Validators.required],
              value: ['', Validators.required],
            }),
          ]),
        }),
        events: this.fb.array(
          [
            this.fb.group({
              type: ['', Validators.required],
              code: ['', Validators.required],
            }),
          ],
          [Validators.required, Validators.minLength(1)]
        ),
      }),
    });
  }

  get proofs(): FormArray {
    return this.creationForm.get('personPrivacy.action.proof') as FormArray;
  }

  addProof(): void {
    this.proofs.push(
      this.fb.group({
        code: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  removeProof(index: number): void {
    this.proofs.removeAt(index);
  }

  get sources(): FormArray {
    return this.creationForm.get('personPrivacy.action.source') as FormArray;
  }

  addSource(): void {
    this.sources.push(
      this.fb.group({
        code: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  removeSource(index: number): void {
    this.sources.removeAt(index);
  }

  get events(): FormArray {
    return this.creationForm.get('personPrivacy.events') as FormArray;
  }

  addEvent(): void {
    this.events.push(
      this.fb.group({
        type: ['', Validators.required],
        code: ['', Validators.required],
      })
    );
  }

  removeEvent(index: number): void {
    this.events.removeAt(index);
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  submitForm() {
    if (this.creationForm.valid) {
      this.isSubmitting = true;
      const privacy: PrivacyEventsRequest = this.creationForm.value['personPrivacy'];
      if (privacy) {
        privacy.action.language = privacy.action.language.toLowerCase();
        if (!privacy.action.date) privacy.action.date = new Date();
        if (privacy.action.proof?.length === 0) delete privacy.action.proof;
        if (privacy.action.source?.length === 0) delete privacy.action.source;
      }
      this.apiCmdService
        .createPerson({
          data: {
            fieraId: this.creationForm.value['fieraId'] || undefined,
            email: this.creationForm.value['email'],
            fiscalCode: this.creationForm.value['fiscalCode'] || undefined,
            firstName: this.creationForm.value['firstName'],
            lastName: this.creationForm.value['lastName'],
            touchpointName: 'backoffice',
            touchpointType: 'webapp',
            personPrivacy: privacy ? [privacy] : undefined,
            personProfile: undefined,
            personContacts: undefined,
            personCompanyProfiles: undefined,
            /*touchpointPersonIngress: {
              ingressDate: new Date(),
              ingressRole: '',
              touchpointName: 'web-app-cmd',
              touchpointType: 'web',
              touchpointSource: '',
            },*/
          },
        })
        .subscribe({
          next: response => {
            this.isSubmitting = false;
            const personId = response.data;
            this.router.navigate(['/persone/edit', personId]);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
        });
    } else {
    }
  }

  navigateBack() {
    this.router.navigate(['/persone']);
  }
}

import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { Router } from '@angular/router';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { NgForOf, NgIf } from '@angular/common';
import { ANSWER_TYPES } from '../../../constants/question.constants';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';

@Component({
  selector: 'app-create-question',
  standalone: true,
  imports: [
    MatCard,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatButton,
    MatLabel,
    MatError,
    MatOption,
    MatSelect,
    MatIcon,
    MatIconButton,
    NgForOf,
    HasRoleModule,
    NgIf,
    MatProgressSpinner,
    CdkTextareaAutosize,
    TextFieldModule,
  ],
  templateUrl: './create-question.component.html',
  styleUrl: './create-question.component.scss',
})
export class CreateQuestionComponent {
  creationForm: FormGroup;
  protected readonly ANSWER_TYPES = ANSWER_TYPES;
  public isSubmitting = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.creationForm = this.fb.group({
      answerType: ['', Validators.required],
      maxAnswers: ['', [Validators.min(1)]],
      questionTexts: this.fb.array([
        this.fb.group({
          languageCode: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
          text: ['', Validators.required],
        }),
      ]),
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  get questionTexts(): FormArray {
    return this.creationForm.get('questionTexts') as FormArray;
  }

  addQuestionText(): void {
    this.questionTexts.push(
      this.fb.group({
        languageCode: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
        text: ['', Validators.required],
      })
    );
  }

  removeQuestionText(index: number): void {
    this.questionTexts.removeAt(index);
  }

  submitForm() {
    if (this.creationForm.valid) {
      this.isSubmitting = true;
      this.apiCmdService
        .createQuestion({
          data: {
            answerType: this.creationForm.value['answerType'],
            maxAnswers: this.creationForm.value['maxAnswers'] || undefined,
            questionTexts: this.creationForm.value['questionTexts'],
          },
        })
        .subscribe({
          next: response => {
            this.isSubmitting = false;
            const questionId = response.data;
            this.router.navigate(['/domande/edit', questionId]);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
        });
    } else {
    }
  }

  navigateBack() {
    this.router.navigate(['/domande']);
  }
}

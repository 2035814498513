export const EVENT_TYPES = [
  /*
  { label: 'consent', value: 'consent' },
  { label: 'withdraw-consent', value: 'withdraw-consent' },
  { label: 'proposed-consent', value: 'proposed-consent' },
  { label: 'policy-acknowledgment', value: 'policy-acknowledgment' },
  { label: 'document-acknowledgment', value: 'document-acknowledgment' },
  */
  { label: 'Consenso accettato', value: 'consent' },
  { label: 'Consenso rifiutato', value: 'withdraw-consent' },
  { label: 'Consenso proposto', value: 'proposed-consent' },
  { label: 'Presa visione informativa', value: 'policy-acknowledgment' },
  { label: 'Presa visione termini e condizioni', value: 'document-acknowledgment' },
];
export function getEventTypeLabel(value: string): string {
  const item = EVENT_TYPES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
export const EVENT_TYPE_CONSENT_VALUES = [
  { label: 'Accettato', value: 'on' },
  { label: 'Rifiutato', value: 'off' },
  { label: 'Proposto', value: 'proposed' },
  { label: 'Scaduto', value: 'expired' },
];
export function getEventTypeConsentLabel(value: string): string {
  const item = EVENT_TYPE_CONSENT_VALUES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
// todo: mettere lista completa dopo modellazione
export const PROOFS = [
  { label: 'IP', value: 'IP' },
  { label: 'URL', value: 'URL' },
];
export function getProofLabel(value: string): string {
  const item = PROOFS.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
// todo: mettere lista completa dopo modellazione
export const SOURCES = [{ label: 'TOUCHPOINT', value: 'TOUCHPOINT' }];
export function getSourceLabel(value: string): string {
  const item = SOURCES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const ACTION_LANGUAGES = [
  { label: 'Italiano', value: 'it' },
  { label: 'Inglese', value: 'en' },
];
export function getActionLanguageLabel(value: string): string {
  const item = ACTION_LANGUAGES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
// todo: lista di event codici da api

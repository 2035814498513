<div class="snackbar-content">
    <div class="head">
        <span class="title"><mat-icon>error</mat-icon>Errore</span>
        <button class="close-button" mat-icon-button (click)="closeSnackbar()">
          <mat-icon>close</mat-icon>
        </button>
    </div>
  <div class="message">{{ data.message }}</div>
  <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
</div>

<div>
  <mat-toolbar class="toolbar-container">
    <!--logo-->
    <span class="logo-container">
      <img ngSrc="assets/logo.png" alt="CMD Logo" class="logo" height="60" width="280" priority="priority" />
    </span>
    <span class="spacer"></span>
    <!--legal entity-->
    <mat-form-field appearance="fill" class="entity-select">
      <mat-label class="entity-select__label">Legal Entity</mat-label>
      <mat-select class="select" [disabled]="!canChangeEntity"  [formControl]="legalEntityControl" (selectionChange)="onSelectionChange($event)" required>
        <mat-option>--</mat-option>
        @for (legalEntity of legalEntities; track $index) {
          <mat-option [value]="legalEntity">{{ legalEntity.name }} ({{ legalEntity.code }})</mat-option>
        }
      </mat-select>
      @if (legalEntityControl.hasError('required')) {
        <mat-error>Seleziona una legal entity</mat-error>
      }
    </mat-form-field>
    <!--profile-->
    @if (authAppService.backofficeUserProfile) {
      <button class="user-icon" mat-icon-button [matMenuTriggerFor]="userMenu">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #userMenu="matMenu" class="user-profile-menu">
        <div class="user-profile-content">
          <p><strong>email:</strong> {{ authAppService.backofficeUserProfile.email }}</p>
          <p><strong>Nome:</strong> {{ authAppService.backofficeUserProfile.firstName }}</p>
          <p><strong>Cognome:</strong> {{ authAppService.backofficeUserProfile.lastName }}</p>
          <p><strong>Ruolo:</strong> {{ getRoleLabel(authAppService.backofficeUserProfile.role) }}</p>
          <p><strong>Legal Entity:</strong> {{ authAppService.backofficeUserProfile.legalEntityCodes.join(' | ') }}</p>
        </div>
        <button mat-menu-item (click)="authAppService.logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    }
  </mat-toolbar>
  <div class="page-title">
    <span class="menu-icon" *ngIf="isMobile" (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </span>
    <!--title page-->
    <h2 class="page-title">{{ title }}</h2>
  </div>
  <mat-sidenav-container class="sidenav-container">
    <!--sidebar-->
    <!--  <mat-sidenav #drawer class="sidenav" mode="side" [opened]="true" fixedInViewport>-->
    <mat-sidenav #sidenav mode="side" [opened]="isMobile ? sideNavOpened : true" [attr.bindOpened]="isMobile ? null : ''" class="sidenav">
      <!--menu-->
      <mat-nav-list>
        <a
          *appHasRole="['viewer', 'editor', 'admin', 'owner']"
          mat-list-item
          routerLink="/persone"
          matTooltip="Persone"
          [attr.bindClick]="!isMobile ? null : ''"
          (click)="isMobile ? sidenav.close() : null"
        >
          <mat-icon>person</mat-icon>
          <span>Persone</span>
        </a>
        <a
          *appHasRole="['viewer', 'editor', 'admin', 'owner']"
          mat-list-item
          routerLink="/aziende"
          matTooltip="Aziende"
          [attr.bindClick]="!isMobile ? null : ''"
          (click)="isMobile ? sidenav.close() : null"
        >
          <mat-icon>business</mat-icon>
          <span>Aziende</span>
        </a>
        <a
          *appHasRole="['viewer', 'editor', 'admin', 'owner']"
          mat-list-item
          routerLink="/domande"
          matTooltip="Domande"
          [attr.bindClick]="!isMobile ? null : ''"
          (click)="isMobile ? sidenav.close() : null"
        >
          <mat-icon>chat</mat-icon>
          <span>Domande</span>
        </a>
        <a
          *appHasRole="['viewer', 'editor', 'admin', 'owner']"
          mat-list-item
          routerLink="/edizioni"
          matTooltip="Edizioni"
          [attr.bindClick]="!isMobile ? null : ''"
          (click)="isMobile ? sidenav.close() : null"
        >
          <mat-icon>event</mat-icon>
          <span>Edizioni</span>
        </a>
        <a
          *appHasRole="['owner', 'admin']"
          mat-list-item
          routerLink="/configurazioni-tp"
          matTooltip="Configurazioni Touchpoint"
          [attr.bindClick]="!isMobile ? null : ''"
          (click)="isMobile ? sidenav.close() : null"
        >
          <mat-icon fontIcon="settings"></mat-icon>
          <span>Configurazioni TP</span>
        </a>
        <a
          *appHasRole="['owner']"
          mat-list-item
          routerLink="/utenti-backoffice"
          matTooltip="Utenti Backoffice"
          [attr.bindClick]="!isMobile ? null : ''"
          (click)="isMobile ? sidenav.close() : null"
        >
          <mat-icon fontIcon="supervisor_account"></mat-icon>
          <span>Utenti Backoffice</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<!--<router-outlet *ngIf="!isAuthenticated"></router-outlet>-->

<h1 mat-dialog-title>
  Nuovo contatto
  <button mat-button mat-dialog-close>X</button>
</h1>
<div mat-dialog-content>
  <form [formGroup]="contactForm">
    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="contactType" required>
        <mat-option *ngIf="contactTypes.length === 0" disabled>
          Tutti i tipi di contatto sono stati aggiunti
        </mat-option>
        <mat-option *ngFor="let type of contactTypes" [value]="type.value">
          {{ type.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Valore</mat-label>
      <input matInput formControlName="contactValue" required />
    </mat-form-field>

    <mat-checkbox formControlName="isPreferred">Preferito</mat-checkbox>
  </form>
</div>

<br />

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annulla</button>
  <button mat-button (click)="onSubmit()" [disabled]="contactForm.invalid">Salva</button>
</div>

import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgForOf, NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { GetQuestionResponse } from '@packages/api-cmd-type-definitions/lib/questions/get-question.dto';
import { DataUpdateQuestionApiRequestDto } from '@packages/api-cmd-type-definitions/lib/questions/update-question.dto';
import { DataCreateQuestionTextApiRequestDto } from '@packages/api-cmd-type-definitions/lib/questions/create-question-text.dto';
import { DataUpdateQuestionTextApiRequestDto } from '@packages/api-cmd-type-definitions/lib/questions/update-question-text.dto';
import { ANSWER_TYPES, getAnswerTypeLabel } from '../../../constants/question.constants';
import { GetQuestionTextResponse } from '@packages/api-cmd-type-definitions/lib/questions/get-question-text.dto';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';

@Component({
  selector: 'app-edit-question',
  standalone: true,
  imports: [
    MatLabel,
    MatIcon,
    MatButton,
    MatCard,
    MatCardContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatIconButton,
    MatError,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
    HasRoleModule,
    MatProgressSpinner,
    CdkTextareaAutosize,
    TextFieldModule
  ],
  templateUrl: './edit-question.component.html',
  styleUrl: './edit-question.component.scss',
})
export class EditQuestionComponent implements OnInit {
  questionForm: FormGroup;
  question: GetQuestionResponse | undefined;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private apiCmdService = inject(ApiCmdService);
  protected readonly ANSWER_TYPES = ANSWER_TYPES;
  public isLoading = true;
  public isSubmitting = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    this.questionForm = this.fb.group({
      answerType: ['', Validators.required],
      maxAnswers: [0, [Validators.min(1)]],
      questionTexts: this.fb.array([]),
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.loadQuestion();
  }

  get questionTexts(): FormArray {
    return this.questionForm.get('questionTexts') as FormArray;
  }

  loadQuestion(): void {
    const questionId = this.route.snapshot.paramMap.get('id');
    if (questionId) {
      this.isLoading = true;
      this.apiCmdService.getQuestion(questionId).subscribe({
        next: response => {
          this.question = response.data;
          this.questionForm.patchValue(this.question);
          this.setQuestionTexts(this.question.questionTexts);
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  setQuestionTexts(texts: GetQuestionTextResponse[]): void {
    const textFGs = texts.map(text =>
      this.fb.group({
        id: [text.id],
        languageCode: [text.languageCode, Validators.required],
        text: [text.text, Validators.required],
        _delete: [false],
      })
    );
    const textFormArray = this.fb.array(textFGs);
    this.questionForm.setControl('questionTexts', textFormArray);
  }

  addQuestionText(): void {
    this.questionTexts.push(
      this.fb.group({
        id: [''],
        languageCode: ['', Validators.required],
        text: ['', Validators.required],
        _delete: [false],
      })
    );
  }

  deleteQuestionText(index: number): void {
    const control = this.questionTexts.at(index);
    control.get('_delete')?.setValue(true);
  }

  onSubmit(): void {
    if (this.questionForm.valid && this.question) {
      this.isSubmitting = true;
      const updateDto: DataUpdateQuestionApiRequestDto['data'] = {
        answerType: this.questionForm.value.answerType,
        maxAnswers: this.questionForm.value.maxAnswers === '' ? null : this.questionForm.value.maxAnswers,
      };
      this.apiCmdService.updateQuestion(this.question.id, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.updateQuestionTexts();
          this.loadQuestion();
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  incrementMaxAnswers(): void {
    const currentValue = this.questionForm.get('maxAnswers')?.value || 0;
    this.questionForm.get('maxAnswers')?.setValue(currentValue + 1);
  }

  decrementMaxAnswers(): void {
    const currentValue = this.questionForm.get('maxAnswers')?.value || 0;
    if (currentValue > 0) {
      this.questionForm.get('maxAnswers')?.setValue(currentValue - 1);
    }
  }

  deleteQuestion(): void {
    if (this.question) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.question) {
            this.apiCmdService.deleteQuestion(this.question.id).subscribe({
              next: () => {
                this.navigateBack();
              },
              error: err => handlerSnackbarApiError(this.snackbar, err),
            });
          }
        }
      });
    }
  }

  updateQuestionTexts(): void {
    const questionId = this.question?.id;
    if (!questionId) return;
    const texts = this.questionTexts.value;
    console.log(texts);
    const observables = texts.map((text: any) => {
      if (text.id && text._delete) {
        // Delete existing text
        return this.apiCmdService.deleteQuestionText(questionId, text.id);
      } else if (text.id) {
        // Update existing text
        const updateDto: DataUpdateQuestionTextApiRequestDto['data'] = { languageCode: text.languageCode, text: text.text };
        return this.apiCmdService.updateQuestionText(questionId, text.id, { data: updateDto });
      } else {
        // Create new text
        const createDto: DataCreateQuestionTextApiRequestDto['data'] = { languageCode: text.languageCode, text: text.text };
        return this.apiCmdService.createQuestionText(questionId, { data: createDto });
      }
    });

    // Execute all requests and reload question texts
    Promise.all(observables.map((obs: any) => obs.toPromise()))
      .then(() => {
        this.loadQuestion();
      })
      .catch(err => handlerSnackbarApiError(this.snackbar, err));
  }

  navigateBack(): void {
    this.router.navigate(['/domande']);
  }
}

<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
    <h3>Filtri<button class="close-dialog" mat-button mat-dialog-close>X</button></h3>

    <mat-form-field appearance="outline">
      <mat-label>Codice touchpoint</mat-label>
      <input matInput formControlName="touchpointCode" placeholder="Ricerca Codice completo" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Codice macromanifestazione</mat-label>
      <input matInput formControlName="exhibitionGroupCode" placeholder="Ricerca Codice completo" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Codice manifestazione</mat-label>
      <input matInput formControlName="exhibitionCode" placeholder="Ricerca Codice completo" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Codice TP TrustGuardian</mat-label>
      <input matInput formControlName="tgTouchpointConfigCode" placeholder="Ricerca Codice completo" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Descrizione</mat-label>
      <input matInput formControlName="description" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <button mat-raised-button color="primary" (click)="resetFilters()">
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>
</div>

export const ANSWER_TYPES = [
  { label: 'Chiusa', value: 'close' },
  { label: 'Multipla', value: 'multiple' },
  { label: 'Aperta', value: 'open' },
];
export function getAnswerTypeLabel(value: string): string {
  const item = ANSWER_TYPES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
// todo: mettere lista lingua domanda

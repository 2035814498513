import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-create-edition',
  standalone: true,
  imports: [FormsModule, MatButton, MatCard, MatFormField, MatInput, MatLabel, ReactiveFormsModule, MatIcon, MatIconButton, HasRoleModule, NgIf, MatProgressSpinner],
  templateUrl: './create-edition.component.html',
  styleUrl: './create-edition.component.scss',
})
export class CreateEditionComponent {
  creationForm: FormGroup;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.creationForm = this.fb.group({
      editionCode: ['', Validators.required],
      editionName: ['', Validators.required],
      exhibitionCode: ['', Validators.required],
      exhibitionName: ['', Validators.required],
      exhibitionGroupCode: ['', Validators.required],
      exhibitionGroupName: ['', Validators.required],
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  submitForm() {
    if (this.creationForm.valid) {
      this.isSubmitting = true;
      this.apiCmdService
        .createEdition({
          data: {
            editionCode: this.creationForm.value['editionCode'] || undefined,
            editionName: this.creationForm.value['editionName'] || undefined,
            exhibitionCode: this.creationForm.value['exhibitionCode'] || undefined,
            exhibitionName: this.creationForm.value['exhibitionName'] || undefined,
            exhibitionGroupCode: this.creationForm.value['exhibitionGroupCode'] || undefined,
            exhibitionGroupName: this.creationForm.value['exhibitionGroupName'] || undefined,
          },
        })
        .subscribe({
          next: response => {
            this.isSubmitting = false;
            const edtionCode = response.data;
            this.router.navigate(['/edizioni/edit', edtionCode]);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
        });
    } else {
    }
  }

  navigateBack() {
    this.router.navigate(['/edizioni']);
  }
}

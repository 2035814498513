import { Component, computed, HostListener, inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, map, Observable } from 'rxjs';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { AppStateService } from './services/app-state.service';
import { LegalEntity } from './types/legal-entity.type';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { getRoleLabel } from './constants/backoffice-user.constants';
import { HasRoleModule } from './modules/has-role.module';
import { AuthAppService } from './services/auth-app.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatSidenavContent,
    MatToolbar,
    MatSidenavContainer,
    MatNavList,
    MatFormField,
    MatSelect,
    MatOption,
    MatIcon,
    MatMenu,
    MatSidenav,
    MatMenuTrigger,
    MatLabel,
    MatIconButton,
    MatMenuItem,
    MatListItem,
    RouterLink,
    MatTooltip,
    NgOptimizedImage,
    ReactiveFormsModule,
    MatError,
    HasRoleModule,
    NgIf,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isHandset$: Observable<boolean>;
  title = 'CMD';
  public $loading = computed(() => this.appStateService.$loading());
  public appStateService = inject(AppStateService);
  private $legalEntityCodeSelected = computed(() => this.appStateService.$legalEntityCodeSelected());
  legalEntityControl = new FormControl<LegalEntity | null>(null, Validators.required);
  protected readonly getRoleLabel = getRoleLabel;
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private breakpointObserver = inject(BreakpointObserver);
  sideNavOpened: boolean = false;
  isMobile: boolean = false;
  protected authAppService = inject(AuthAppService);
  legalEntities: LegalEntity[] = [];
  canChangeEntity: boolean = false;

  constructor() {
    this.isHandset$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(result => result.matches));
  }

  ngOnInit() {
    this.setTitle();
    this.legalEntities = this.authAppService.legalEntities;
    if (this.legalEntities.length > 0) {
      const selectedLegalEntity = this.legalEntities[0];
      this.legalEntityControl.setValue(selectedLegalEntity);
      this.appStateService.setLegalEntityCodeSelected(selectedLegalEntity);
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setTitle();
      /*// @ts-expect-error TS2339
      let url = this.currentRoute.root._routerState.url || ('' as string);
      let [path] = url.split('?');
      const homePath = '/';
      const defaultPath = '/persone';
      path = path === homePath ? defaultPath : path || defaultPath;*/
      // console.log(path);
      //this.authAppService.initializeApp(path);
    });
    this.authAppService.evaluate();
    this.isMobile = window.innerWidth < 768;

    this.router.events.subscribe(() => {
      const route = this.activatedRoute.firstChild;
      if (route) {
        const data = route.snapshot.data;
        this.canChangeEntity = data?.['canChangeEntity'];
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  onSelectionChange(value: any): void {
    if (this.canChangeEntity) {
      this.appStateService.setLegalEntityCodeSelected(value.value);
    }
  }

  get currentRoute(): ActivatedRouteSnapshot {
    let currentRoute = this.router.routerState.snapshot.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute;
  }

  setTitle() {
    let title = '';
    let currentRoute = this.currentRoute;
    if (currentRoute.data['title']) {
      title = currentRoute.data['title'];
    }
    this.title = title;
  }
}

<div class="header create">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h3>Nuova domanda</h3>
</div>
<mat-card>
<!--  todo: uniformare come edit-->
  <form [formGroup]="creationForm" (ngSubmit)="submitForm()">
    <mat-form-field>
      <mat-label>Tipo risposta</mat-label>
      <mat-select formControlName="answerType" required>
        @for (item of ANSWER_TYPES; track $index) {
          <mat-option [value]="item.value">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
      @if (creationForm.get('answerType')?.errors) {
        <mat-error>Seleziona un tipo risposta</mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nr massimo risposte</mat-label>
      <input matInput formControlName="maxAnswers" />
    </mat-form-field>

    <br />

    <div formArrayName="questionTexts">
      <div *ngFor="let questionText of questionTexts.controls; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label>Codice lingua</mat-label>
          <input matInput formControlName="languageCode" />
        </mat-form-field>
        <mat-form-field class="full-field-80 d-block">
          <mat-label>Testo</mat-label>
          <!-- <input matInput formControlName="text" /> -->
          <textarea 
          matInput
          formControlName="text"
          placeholder="Scrivi una domanda..."
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="10"
        ></textarea>
        </mat-form-field>
        <button mat-icon-button color="warn" type="button" (click)="removeQuestionText(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <br />

    <button mat-raised-button color="primary" type="button" (click)="addQuestionText()">Aggiungi testo</button>

    <br />

    <button mat-raised-button color="primary" type="submit" [disabled]="creationForm.invalid || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>
  </form>
</mat-card>
